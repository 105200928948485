var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"_zy341H9wmO6LcDEPiO7g"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { appConfig } from '@app/config';
import * as Sentry from '@sentry/nextjs';

const EXCEPTIONS_TO_SKIP = ['Converting circular structure to JSON'];
const MODULE_ERRORS_TO_SKIP = [
  'node_modules/@sentry',
  'node_modules/@walletconnect',
];

if (appConfig.env === 'production') {
  Sentry.init({
    dsn: appConfig.sentryDsn,

    beforeSend(event, hint) {
      const shouldSkipModuleError = event.exception?.values?.some(
        (exception) => {
          return exception.stacktrace?.frames?.some((frame) => {
            return MODULE_ERRORS_TO_SKIP.some(
              (module) => frame?.filename?.includes(module),
            );
          });
        },
      );

      if (shouldSkipModuleError) {
        return null;
      }

      if (event?.exception && event?.exception?.values) {
        for (const exception of event.exception.values) {
          if (
            EXCEPTIONS_TO_SKIP.some(
              (errorToSkip) =>
                exception?.value && exception?.value?.includes(errorToSkip),
            )
          ) {
            return null;
          }
        }
      }

      return event;
    },

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });
}
