import { Address } from 'wagmi';

export const appConfig = {
  env: process.env.NEXT_PUBLIC_ENV as string,
  alchemyApiKey: process.env.NEXT_PUBLIC_ALCHEMY_PROVIDER_API_KEY as string,
  websocketRpcUrl: process.env.NEXT_PUBLIC_WEBSOCKET_RPC_URL as string,
  blockExplorerUrl: process.env.NEXT_PUBLIC_BLOCK_EXPLORER_URL as string,
  googleAnalyticsTag: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS as string,
  googleAnalyticsTrackingId: process.env
    .NEXT_PUBLIC_GOOGLE_TRACKING_ID as string,
  facebookTrackingId: process.env.NEXT_PUBLIC_FACEBOOK_TRACKING_ID as string,
  requiredChainId: process.env.NEXT_PUBLIC_REQUIRED_CHAIN_ID as string,
  sentryAuthToken: process.env.SENTRY_AUTH_TOKEN as string,
  sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN as string,
  tawkWidgetId: process.env.NEXT_PUBLIC_TAWK_WIDGET_ID as string,
  tawkPropertyId: process.env.NEXT_PUBLIC_TAWK_PROPERTY_ID as string,
  walletConnectProjectId: process.env
    .NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID as string,
  api: {
    investorApiKey: process.env.NEXT_PUBLIC_INVESTOR_API_KEY as string,
    investorApiUrl: process.env.NEXT_PUBLIC_INVESTOR_API_URL as string,
  },
  contracts: {
    tether: process.env.NEXT_PUBLIC_TETHER_CONTRACT_ADDRESS as Address,
    tokenSale: process.env.NEXT_PUBLIC_TOKEN_SALE_CONTRACT_ADDRESS as Address,
    wofrToken: process.env.NEXT_PUBLIC_WOFR_TOKEN_ADDRESS as Address,
  },
  telegramBotName: process.env.NEXT_PUBLIC_TELEGRAM_BOT_NAME as string,
  telegramBotId: process.env.NEXT_PUBLIC_TELEGRAM_BOT_ID as string,
  telegramSlapUrl: process.env.NEXT_PUBLIC_TELEGRAM_SLAP_URL as string,
};
